import { Pie } from 'vue-chartjs'
import ChartDataLabels from 'chartjs-plugin-datalabels';


export default {
  extends: Pie,
  props: ['data', 'options'],
  mounted () {
    this.addPlugin(ChartDataLabels);
    this.renderChart(this.data, this.options)
  }
}
<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <div class="panel">
          <div class="panel-heading bg-dark text-light">
            <h3 class="panel-title bold">Data Equipment</h3>
          </div>
          <div class="panel-body p-0">
            <vue-good-table
              mode="remote"
              @on-page-change="onPageChange"
              @on-per-page-change="onPerPageChange"
              @on-search="onSearch"
              :totalRows="totalRecords"
              :isLoading.sync="isLoading"
              :search-options="{
                enabled: true,
                trigger: 'keyup',
                placeholder: 'Pencarian',
              }"
              :pagination-options="{
                enabled: true,
                perPageDropdownEnabled: true,
                perPageDropdown: [10, 20, 30, 40, 50, 100],
                dropdownAllowAll: false,
                position: 'bottom',
                rowsPerPageLabel: '',
                nextLabel: '',
                prevLabel: '',
                ofLabel: 'of',
                pageLabel: 'page',
                allLabel: '',
              }"
              :rows="rows"
              :columns="columns"
            >
              <div slot="emptystate" class="text-center">
                {{ tableInfo }}
              </div>
              <div slot="table-actions">
                <div style="display: flex">
                  <button
                    type="button"
                    v-on:click="fetchData()"
                    class="btn btn-default mr-2"
                  >
                    <i
                      class="fa fa-sync"
                      :class="{ 'fa-spin': this.isLoading }"
                    ></i>
                  </button>
                  <export-excel
                    :data   = "rows">
                    <button
                    type="button"
                    class="btn btn-default mr-2"
                  >
                    <i
                      class="fa fa-print"
                      :class="{ 'fa-spin': this.isLoading }"
                    ></i>
                  </button>               
                </export-excel>
                  <button
                    type="button"
                    @click="showModal"
                    class="btn btn-primary mr-2"
                  >
                    <i class="fa fa-plus d-md-none"></i>
                    <span class="d-none d-md-block">Tambah Equipment</span>
                  </button>
                </div>
              </div>
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'action'">
                  <div>
                    <button
                      type="button"
                      @click="fetchDetail(props.row.id_equipment)"
                      class="btn btn-icon btn-warning m-r-5"
                    >
                      <i class="fa fa-pencil-alt"></i>
                    </button>
                    <button
                      class="btn btn-icon btn-danger"
                      v-on:click="confirmDelete('equipment', props.row.id_equipment)"
                    >
                      <i class="fa fa-trash-alt"></i>
                    </button>
                  </div>
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
    <!-- modal -->
    <b-modal v-model="modalVisible" @hide="beforeModalClose">
      <template #modal-header>
        <h5 class="modal-title">Form Equipment</h5>
      </template>
      <div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label class="f-w-600" for="nama_cat_maintenance"
                >Nama Equipment
              </label>
              <input
                v-model="form.nama_equipment"
                type="text"
                class="form-control"
                placeholder="Input Nama Equipment"
                required
                @input="formValidate.nama_equipment = ''"
                :class="{
                  'is-valid': form.nama_equipment != '',
                  'is-invalid': formValidate.nama_equipment || form.nama_equipment == '',
                }"
              />
            </div>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <button @click="closeModal" class="btn btn-info" variant="primary">
          Tutup
        </button>
        <button
          class="btn btn-success"
          @click="postData"
          variant="primary"
          :disabled="loading"
        >
          <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
          Submit
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import userData from "../../../plugins/userData";
import deleteData from "../../../plugins/deleteData";
import paginations from "../../../plugins/paginations";

export default {
  mixins: [userData, deleteData, paginations],
  data() {
    return {
      columns: [
        {
          label: "Nama Equipment",
          field: "nama_equipment",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-nowrap",
        },
        {
          label: "Aksi",
          field: "action",
          sortable: false,
          width: "100px",
          tdClass:
            "text-center f-s-14 f-w-600 text-inverse valign-middle text-nowrap",
          thClass: "text-center text-nowrap",
        },
      ],
      rows: null,
      tableInfo: "Loading data",
      isLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: "",
            type: "",
          },
        ],
        page: 1,
        perPage: 10,
      },
      filter: {
        district: "",
        village: "",
      },
      form: {
        id_equipment: "",
        nama_equipment: "",
      },
      searchTerm: "",
      timeoutSearch: null,
      loadDPT: false,
      formValidate: [],
      districtList: [],
      villageList: [],
      modalVisible: false,
      conditionMet: false,
      loading: false,
      statusData: false,
      updateMode: false,
      linkPost: this.$url+"/v2/equipment/create/",
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    postData() {

      // console.log(this.$url);
      if (this.updateMode) {
        this.linkPost = this.$url+"/v2/equipment/update/";
      }else{
        this.linkPost = this.$url+"/v2/equipment/create/";
      }

      this.loading = true;
      const formData = new FormData();
      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }
      axios
        .post(this.linkPost, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              showConfirmButton: false,
            });
            setTimeout(() => {
              this.fetchData();
              this.closeModal();
              Swal.close();
            }, 700);
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          this.error = error.message;
        });
    },
    fetchData() {
      this.isLoading = true;
      axios
        .get(this.$url+"/v2/equipment/", {
          headers: {
            token: this.userToken,
          },
          params: {
            search: this.searchTerm,
            page: this.serverParams.page,
            limit: this.serverParams.perPage,
          },
        })
        .then((response) => {
          this.rows = response.data.data;
          this.totalRecords = response.data.total;
          if (this.totalRecords <= 0) {
            this.tableInfo = "Empty data";
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
          this.error = error.message;
        });
    },
    fetchDetail(id) {
      axios
        .get(this.$url+"/v2/equipment/detail/" + id)
        .then((response) => {
          for (let key in response.data.data) {
            this.form[key] = response.data.data[key];
          }
          this.updateMode = true;
          this.showModal();
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    showModal() {
      this.modalVisible = true;
      this.conditionMet = false;
    },
    closeModal() {
      this.modalVisible = false;
      this.conditionMet = true;
      this.updateMode = false;
      this.resetForm();
    },
    beforeModalClose(evt) {
      if (!this.conditionMet) {
        evt.preventDefault();
      }
    },
    resetForm() {
      for (let key in this.form) {
        this.form[key] = "";
      }
      this.linkPost = this.$url+"/v2/equipment/create/";
    },
  },
};
</script>
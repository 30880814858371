<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <div class="panel">
          <div class="panel-heading bg-dark text-light">
            <h3 class="panel-title bold">Data Maintenance</h3>
          </div>
          <div class="panel-body p-0">
            <vue-good-table
              mode="remote"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              @on-search="onSearch"
              :totalRows="totalRecords"
              :isLoading.sync="isLoading"
              :search-options="{
                enabled: true,
                trigger: 'keyup',
                placeholder: 'Pencarian',
              }"
              :pagination-options="{
                enabled: true,
                perPageDropdownEnabled: true,
                perPageDropdown: [20, 40, 80, 100],
                dropdownAllowAll: false,
                position: 'bottom',
                rowsPerPageLabel: '',
                nextLabel: '',
                prevLabel: '',
                ofLabel: 'of',
                pageLabel: 'page',
                allLabel: '',
              }"
              :rows="rows"
              :columns="columns"
            >
              <div slot="emptystate" class="text-center">
                {{ tableInfo }}
              </div>
              <div slot="table-actions">
                <div style="display: flex">
                  <button
                    type="button"
                    v-on:click="fetchData()"
                    class="btn btn-default mr-2"
                  >
                    <i
                      class="fa fa-sync"
                      :class="{ 'fa-spin': this.isLoading }"
                    ></i>
                  </button>
                  <export-excel
                    :data   = "rows">
                    <button
                    type="button"
                    class="btn btn-default mr-2"
                  >
                    <i
                      class="fa fa-print"
                      :class="{ 'fa-spin': this.isLoading }"
                    ></i>
                  </button>               
                </export-excel>
                  <router-link
                    to="/master/maintenance/create"
                    class="btn btn-primary mr-2"
                  >
                    <i class="fa fa-plus d-md-none"></i>
                    <span class="d-none d-md-block">Data Baru</span>
                  </router-link>
                </div>
              </div>
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'checklist'">
                  <i v-if="props.row.isChecked == 'true'" class="fas fa-check"></i>
                  <i v-if="props.row.isChecked == 'false'" class="fas fa-desktop"></i>
                </span>

                <span v-if="props.column.field == 'action'">
                  <div>
                    <router-link
                      :to="
                        '/master/maintenance/update/' + props.row.id_maintenance
                      "
                      class="btn btn-icon btn-warning m-r-5"
                    >
                      <i class="fa fa-pencil-alt"></i>
                    </router-link>
                    <button
                      class="btn btn-icon btn-danger"
                      v-on:click="
                        confirmDelete('maintenance', props.row.id_maintenance)
                      "
                    >
                      <i class="fa fa-trash-alt"></i>
                    </button>
                  </div>
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import userData from "../../../plugins/userData";
import deleteData from "../../../plugins/deleteData";
import paginations from "../../../plugins/paginations";

export default {
  mixins: [userData, deleteData, paginations],
  data() {
    return {
      columns: [
        {
          label: "Category Maintenance",
          field: "nama_cat_maintenance",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-nowrap",
        },
        {
          label: "Item Maintenance",
          field: "item_maintenance",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-wrap",
        },
        {
          label: "Standar Maintenance",
          field: "standard_maintenance",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-wrap",
        },
        {
          label: "Checklist Maintenance",
          field: "checklist",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-center text-wrap",
        },
        {
          label: "Aksi",
          field: "action",
          sortable: false,
          width: "100px",
          tdClass:
            "text-center f-s-14 f-w-600 text-inverse valign-middle text-nowrap",
          thClass: "text-center text-nowrap",
        },
      ],
      rows: null,
      tableInfo: "Loading data",
      isLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: "",
            type: "",
          },
        ],
        page: 1,
        perPage: 20,
      },
      filter: {
        district: "",
        village: "",
      },
      searchTerm: "",
      timeoutSearch: null,
      loadDPT: false,
      districtList: [],
      villageList: [],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      axios
        .get(this.$url+"/v2/maintenance/", {
          headers: {
            token: this.userToken,
          },
          params: {
            search: this.searchTerm,
            page: this.serverParams.page,
            limit: this.serverParams.perPage,
            district: this.filter.district,
            village: this.filter.village,
          },
        })
        .then((response) => {
          this.rows = response.data.data;
          this.totalRecords = response.data.total;
          if (this.totalRecords <= 0) {
            this.tableInfo = "Empty data";
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
  },
};
</script>
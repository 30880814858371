var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"panel"},[_vm._m(0),_c('div',{staticClass:"panel-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"bold",attrs:{"for":"id_perusahaan_customer"}},[_vm._v("Perusahaan ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.id_perusahaan_customer),expression:"form.id_perusahaan_customer"}],staticClass:"form-control",class:{
                    'is-valid': _vm.form.id_perusahaan_customer != '',
                    'is-invalid':
                      _vm.formValidate.id_perusahaan_customer ||
                      _vm.form.id_perusahaan_customer == '',
                  },attrs:{"type":"text","required":""},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "id_perusahaan_customer", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){_vm.formValidate.id_perusahaan_customer = ''}]}},[_c('option',{attrs:{"value":"","disabled":""}},[_vm._v("-Pilih-")]),_vm._l((_vm.listPerusahaan),function(perusahaan){return _c('option',{key:perusahaan.id_perusahaan_customer,domProps:{"value":perusahaan.id_perusahaan_customer}},[_vm._v(" "+_vm._s(perusahaan.nama_perusahaan_customer)+" ")])})],2)])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"bold",attrs:{"for":"nama_customer"}},[_vm._v("Nama Customer ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.nama_customer),expression:"form.nama_customer"}],staticClass:"form-control",class:{
                    'is-valid': _vm.form.nama_customer != '',
                    'is-invalid':
                      _vm.formValidate.nama_customer ||
                      _vm.form.nama_customer == '',
                  },attrs:{"type":"email","placeholder":"Input Nama Customer","required":""},domProps:{"value":(_vm.form.nama_customer)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "nama_customer", $event.target.value)},function($event){_vm.formValidate.nama_customer = ''}]}})])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"bold",attrs:{"for":"isChecked"}},[_vm._v("Hp Customer ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.no_hp_customer),expression:"form.no_hp_customer"}],staticClass:"form-control",class:{
                    'is-valid': _vm.form.no_hp_customer != '',
                    'is-invalid':
                      _vm.formValidate.no_hp_customer ||
                      _vm.form.no_hp_customer == '',
                  },attrs:{"type":"tel","placeholder":"Input Hp Customer","required":""},domProps:{"value":(_vm.form.no_hp_customer)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "no_hp_customer", $event.target.value)},function($event){_vm.formValidate.no_hp_customer = ''}]}})])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"bold",attrs:{"for":"alamat_customer"}},[_vm._v("Alamat Customer ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.alamat_customer),expression:"form.alamat_customer"}],staticClass:"form-control",class:{
                    'is-valid': _vm.form.alamat_customer != '',
                    'is-invalid':
                      _vm.formValidate.alamat_customer ||
                      _vm.form.alamat_customer == '',
                  },attrs:{"type":"tel","placeholder":"Input Alamat Customer","required":""},domProps:{"value":(_vm.form.alamat_customer)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "alamat_customer", $event.target.value)},function($event){_vm.formValidate.alamat_customer = ''}]}})])])])]),_c('div',{staticClass:"panel-footer"},[_c('div',{staticClass:"row"},[_vm._m(1),_c('div',{staticClass:"col-md-6"},[_c('button',{staticClass:"btn btn-success float-md-right",attrs:{"variant":"primary","disabled":_vm.loading},on:{"click":_vm.postData}},[(_vm.loading)?_c('i',{staticClass:"fas fa-circle-notch fa-spin"}):_vm._e(),_vm._v(" Submit ")])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-heading bg-dark text-light"},[_c('h3',{staticClass:"panel-title bold"},[_vm._v("Update Customer")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6 mb-2"},[_c('a',{staticClass:"btn btn-default",attrs:{"href":"javascript:window.history.go(-1);"}},[_vm._v("Kembali")])])
}]

export { render, staticRenderFns }